import React from "react";
import { get } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";

import contactInformation from "../../constants/contactInformation";
import documentDefinitionkeys from "../../constants/documentDefinitionKeys";
import taskDefinitionKeys from "../../constants/taskDefinitionKeys";
import milestoneDefinitionKeys from "../../constants/milestoneDefinitionKeys";

export function mowsubsidiesDossierWrapper() {
  class mowVervoerOpMaatDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation,
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.props.notifications,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
          notifications: this.getNotifications(),
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if (this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier, "label"),
          label: this.context.gettext("Dossier label"),
        });
        dossierInformation.push({
          value: moment(get(this.props.dossier, "updatedAt")).format(
            "DD-MM-YYYY",
          ),
          label: this.context.gettext("Last update"),
        });
      }
      return dossierInformation;
    }

    getDownloads() {
      if (!this.props.dossierDetails) return [];
      const downloads = [];
      if (
        this.props.getDocument(
          documentDefinitionkeys.AANVRAAG_VERVOER_OP_MAAT,
          true,
        )
      ) {
        downloads.push(
          this.props.mapTaskToViewDownloadInfo(
            this.props.getTask(taskDefinitionKeys.AANVRAAG),
            "Aanvraagformulier",
          ),
        );
      }
      return downloads;
    }

    getNotifications() {
      let notifications = [];
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_NIET_MOGELIJK_ONBEKENDE_PARTIJ
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanvraag niet mogelijk"),
            message: this.context.gettext(
              "We hebben geen subsidieovereenkomst Vervoer op Maat gevonden voor uw organisatie. De subsidieovereenkomst moet ondertekend en registreerd zijn in de toepassing voordat u een aanvraag kunt indienen.",
            ),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_NIET_MOGELIJK_GEEN_NIEUWE_DOSSIERS
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanvraag niet mogelijk"),
            message: this.context.gettext(
              "Er bestaan al dossiers voor al uw subsidieovereenkomsten. U kunt geen nieuw dossier starten.",
            ),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_GEANNULEERD
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanvraag geannuleerd"),
            message: this.context.gettext("Uw aanvraag werd geannuleerd."),
            type: notificationTypes.WARNING,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_INGEDIEND
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanvraag ontvangen"),
            message: this.context.gettext(
              "Uw aanvraag werd goed ontvangen en wordt nu verwerkt.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_VOLLEDIG
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanvraag in behandeling"),
            message: this.context.gettext(
              "Uw aanvraag is volledig en wordt verder behandeld.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
          milestoneDefinitionKeys.ONTBREKENDE_INFO &&
        this.props.getTask(taskDefinitionKeys.EXTRA_INFO, true) !== undefined
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Ontbrekende info"),
            message: this.context.gettext(
              "Gelieve bijkomende informatie aan te leveren voor uw dossier.",
            ),
            type: notificationTypes.WARNING,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.ONTBREKENDE_INFO_OPGELADEN
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Ontbrekende informatie opgeladen"),
            message: this.context.gettext(
              "We hebben uw documenten goed ontvangen en zullen uw dossier verder in behandeling nemen.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AFREKENING_INGEDIEND
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext(
              "Afrekening en nieuwe aanvraag ingediend",
            ),
            message: this.context.gettext(
              "Uw afrekening en/of uw nieuwe aanvraag werd goed ontvangen en wordt verwerkt.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      return notifications;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
        notifications: this.state.notifications,
      };

      return <CoreDossierPage {...componentsToPass} />;
    }
  }

  return mowVervoerOpMaatDossier;
}

export default coreDossierWrapper(mowsubsidiesDossierWrapper());
